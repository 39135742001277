@import 'src/scss/mixins';

.button-row{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    font-size: var(--body-font-size);
    font-weight: var(--weight-medium);
    cursor: pointer;
    &--button{
        padding-right: 10px;
    }
}
