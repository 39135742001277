@import 'src/scss/mixins';

.card{
    @include display-flex(flex);
    @include flex-wrap(wrap);
    &__col{
        @include flex(0 0 25%);
        padding: 0 6px 12px;
        max-width: 25%;
    }
    &__block{
        background-color: var(--white-color);
        border-radius: 4px;
        box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.08);
        padding: 15px;
        height: 100%;
    }
    &__block, &__wrap{
        @include display-flex(flex);
        @include justify-content(space-between);
        @include flex-wrap(wrap);
        text-align: left;
        width: 100%;
    }
    &__wrap{
        &:last-child{
            @include align-items(center);
        }
        &:first-child {
            padding-bottom: 7px;
        }
    }
    h4, p, h6{
        text-transform: capitalize;
        word-break: break-all;
    }
    h4{
        padding-bottom: 5px;
        line-height: 18px;
        span{
            display: inline-block;
            color: var(--text-on-teritary);
            font-size: calc(var(--body-font-size) - 2px);
            font-weight: var(--weight-regular);
        }
    }
    p{
        font-size: calc(var(--body-font-size) - 2px);
        line-height: 16px;
    }
    input[type="checkbox"]{
        @include width-height(15px, 15px);
        cursor: pointer;
    }
    &__section{
        span{
            display: block;
            font-size: calc(var(--body-font-size) - 2px);
            line-height: 16px;
            text-transform: capitalize;
        }
        &--version {
            font-size: calc(var(--body-font-size) - 4px);
            text-transform: lowercase !important;
        }
    }
    &__status{
        border-radius: 4px;
        font-size: calc(var(--body-font-size) - 2px);
        line-height: 16px;
        padding: 2px;
        min-width: 75px;
        text-align: center;
    }
    &--publish{
        background-color: var(--status-green);
        color: var(--status-green-text);
    }
    &--draft{
        background-color: var(--status-red);
        color: var(--status-red-text);
    }
    &__add{
        color: var(--primary-color);
        cursor: pointer;
        font-weight: var(--weight-semi-bold);
        font-size: calc(var(--body-font-size) - 2px);
    }
    &--add, &--remove{
        color: var(--primary-color);
        cursor: pointer;
        font-weight: var(--weight-semi-bold);
        font-size: calc(var(--body-font-size) - 2px);
    }
    &--remove{
        color: var(--status-red-text);
    }
}

.card--disabled{
    pointer-events: none;
}

.card--full .card__col{
    @include flex(0 0 100%);
    max-width: 100%;
}

.card--border{
    border: 1px solid var(--border-color);
}

.card__wrap:first-child .card__section:first-child{
    @include flex(0 0 90%);
}

.card__wrap:first-child .card__section:last-child{
    @include flex(0 0 5%);
}

.card__wrap:first-child .card__section:only-child{
    @include flex(0 0 100%);
}

.card--red {
    color: var(--status-red-text);
}

.card--green {
    color: var(--status-green-text);
}

.card--orange {
    color: var(--secondary-color);
}

.card--yellow {
    color: var(--warning-color);
}