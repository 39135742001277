@import 'src/scss/mixins';

.condition-layout {
    margin: 0;
}

.popup-wrapper {
    img {
        padding-bottom: 18px;
    }
    h5 {
        line-height: 20px;
        span {
            color: var(--primary-color);
        }
    }
}

.buttons {
    @include display-flex(flex);
    @include justify-content(flex-end);
    padding-top: 15px;
}