@import 'scss/index';
@import 'scss/mixins';

.card-list{
    &__search {
      background-color: var(--bg-color);
      border: 1px solid var(--disable-color);
      border-radius: 6px;
      position: relative;
      &--wrap{
        @include display-flex(flex);
        @include align-items(center);
        box-sizing: border-box;
        position: relative;
        padding-left: 35px;
        img{
          @include width-height(15px, 15px);
          position: absolute;
          top: 14px;
          left: 10px;
        }
        input{
          @include width-height(100%, 40px);
          background-color: transparent;
          border: none;
          box-sizing: border-box;
          color: var(--text-on-primary);
        }
      }
    }

    ul {
      max-height: 180px;
      overflow-y: auto;
      margin-right: -5px;
      padding-right: 5px;
  
      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar {
        width: 2px;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--text-on-teritary);
      }
      li {
        @include display-flex(flex);
        @include justify-content(space-between);
        @include align-items(center);
        color: var(--text-on-primary);
        border: 1px solid var(--disable-color);
        padding: 7px;
        margin: 4px 0;
        border-radius: 6px;
        font-size: calc(var(--body-font-size) - 2px);
        cursor: pointer;
        text-transform: capitalize;
        span {
          @include width-height(24px, 24px);
          border-radius: 4px;
          color: var(--white-color);
          display: inline-block;
          text-align: center;
          padding: 5px;
          margin-right: 5px;
          line-height: 18px;
          font-size: calc(var(--body-font-size) - 4px);
          text-transform: uppercase;
        }

        input[type=checkbox] {
          @include width-height(16px, 16px);
          border: 1.5px solid var(--disable-color);
          background-color: var(--bg-color);
          border-radius: 8px;
          cursor: pointer;
          margin: 0 10px;
      
          &:checked{
              background-color: #ff0000;
          }
       }
      }
    }
}

