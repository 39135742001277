@import 'src/scss/mixins';

.header{
    @include width-height(100%, 60px);
    background-color:var(--white-color);
    border-bottom: solid 1px rgba(197, 201, 217, 0.4);
    padding: 15px 24px;
    h2{
        font-size: calc(var(--body-font-size) + 6px);
        font-weight: var(--weight-bold);
        padding-bottom: 0;
        text-transform: capitalize;
    }
    a {
        display: inline-block;
    }
}