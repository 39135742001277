@import 'src/scss/mixins';

.priority-tabel {
    width: 100%;

    th {
        color: var(--text-on-secondary);
        font-size: calc(var(--body-font-size) - 2px);
        font-weight: var(--weight-regular);
        line-height: 14px;
        text-align: left;
        input[type="checkbox"] {
            @include width-height(15px, 15px);
            vertical-align: bottom;
            cursor: pointer;
        }
    }
    .center{
        text-align: center;
    }

    th,
    td {
        width: 27%;
        vertical-align: top;
        text-align: left;
        &:first-child{
           width: 10%;
        }
        &.edit{
            width: 10%;
        }
    }

    td{
        table{
            width: 100%;
        }
    }

    &__add {
        @include width-height(20px, 20px);
        border-radius: 50px;
        background-color: var(--primary-color);
        cursor: pointer;
        position: relative;
        text-align: center;
        &--center{
            margin: 0 auto;
        }

        &:before {
            @include width-height(20px, 20px);
            content: "\002B";
            color: var(--white-color);
            font-size: calc(var(--body-font-size) + 2px);
            position: absolute;
            left: 0;
            line-height: 19px;
            text-align: center;
        }
    }

    &__addrow {
        td {
            padding-top: 10px;
            width: 30%;
            &:first-child{
                width: 30%;
            }
            &.is-active {
                .priority-tabel__add {
                    display: none;
                }
            }
        }
    }

    &__header{
        th{
            padding-bottom: 10px;
        }
    }

    &__details{
        th {
            font-weight: var(--weight-medium);
            padding: 10px 0;
            width: 30%;
            &:first-child{
                width: 30%;
            }
            &.edit{
                width: 10%;
            }
        }
        .edit-head{
            padding-left: 6px;
        }
    }

    &__genrow{
        border-bottom: 1px solid var(--border-color);
        text-align: center;
        td {
            font-weight: var(--weight-medium);
            padding: 10px 0;
            width: 30%;
            &:first-child{
                width: 30%;
            }
            // &:last-child{
            //     width: 10%;
            //     text-align: right;
            // }
        }
        .edit-head{
            padding-left: 6px;
        }
        .edit-delete{
            width: 10%;
            img{
                cursor: pointer;
                padding-right: 15px;
            }
        }
    }

    &__version{
        display: inline-block;
        color: var(--text-on-teritary);
        font-size: calc(var(--body-font-size) - 2px);
        font-weight: var(--weight-regular);
    }

    &__message {
        text-align: left;
        &--left{
            text-align: left;
        }
        &--center{
            text-align: center;
        }
    }

    &__row{
        @include display-flex(flex);
        @include align-items(center);
        @include justify-content(flex-end);
    }

    &__message,
    &__row {
        cursor: pointer;
        color: var(--primary-color);
    }

    &__dragarea {
        @include display-flex(flex);
        @include align-items(center);
        @include justify-content(center);
        @include flex-direction(column);
        background-color: var(--strip-bg);
        border: 1px dashed var(--primary-color);
        border-radius: 4px;
        padding: 15px;
        min-height: 125px;
        width: 100%;
        text-align: center;

        h4{
            color: var(--text-on-primary);
            line-height: 18px;
        }

        h6 {
            color: var(--disable-color);
        }

        span {
            color: var(--primary-color);
            cursor: pointer;
            font-weight: var(--weight-semi-bold);
            font-size: calc(var(--body-font-size) - 2px);
        }
    }

    &__textarea{
        padding:0 6px;
        position: relative;
        &:before{
            @include position(absolute, 0, null, null, 0);
            @include width-height(0, 0);
            content: "";
        }
        textarea{
            @include width-height(100%, 80px);
            box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
            border-radius: 4px;
            border: solid 1px var(--border-color);
            background-color:var(--white-color);
            margin-top: 10px;
            padding: 10px;
        }
    }
}

.col-9{
    max-height: calc(100vh - 300px);
}

.aside-bar{
    @include position(absolute !important, 0, 0, null, null);
    height: calc(100vh - 70px);
    background-color: var(--white-color);
    padding-top: 20px;
    overflow-y: hidden;
}

.card-row{
    max-height: calc(100vh - 250px);
}

.card-search {
    background-color: var(--white-color);
    border: solid 1px var(--border-color);
    border-radius: 4px;
    padding-left: 25px;
    margin: 0 6px 12px;
    position: relative;
    input[type="text"] {
        @include width-height(100%, 40px);
        border: none;
        padding: 10px;
    }
    &:before {
        @include position(absolute, 0, null, null, 0);
        @include width-height(40px, 40px);
        // background-image: url("~src/assets/images/search.svg");
        background-position: center;
        background-repeat: no-repeat;
        content: "";
    }
    &__icon {
        @include position(absolute, 12px, null, null, 12px);
        @include width-height(16px, 16px);
    }
}