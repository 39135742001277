@import 'src/scss/mixins';

.popup-card{
    @include position(absolute, 50%, null, null, 58%);
    background-color: var(--white-color);
    box-shadow: 6px 0px 50px 10px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    padding: 15px;
    width: 300px;
    transform: translateY(-50%);
    z-index: 3;
    &:before{
        @include position(absolute, 50%, null, null, -5px);
        @include width-height(5px, 5px);
        border-top: 5px solid transparent;
        border-right: 5px solid var(--white-color);
        border-bottom: 5px solid transparent;
        content: "";
        margin-top: -4px;
    }
    &--left{
        left: -300px;
        &:before{
            display: none;
        }
        &:after{
            @include position(absolute, 50%, -5px, null, null);
            @include width-height(5px, 5px);
            border-top: 5px solid transparent;
            border-left: 5px solid var(--white-color);
            border-bottom: 5px solid transparent;
            content: "";
            margin-top: -4px;
        }
    }
    &--top{
        left: 50px;
        top: -85px;
        &:before{
            display: none;
        }
        &:after{
            @include position(absolute,null, null, -5px, 20px);
            @include width-height(5px, 5px);
            border-right: 5px solid transparent;
            border-top: 5px solid var(--white-color);
            border-left: 5px solid transparent;
            content: "";
        }
    }
}