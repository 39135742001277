@import 'src/scss/mixins';

.content{
    background-color: var(--white-color);
    border-radius: 4px;
    box-shadow: 0 1px 2px 0 rgba(0, 114, 253, 0.16);
    padding: 20px;
    margin-bottom: 12px;
    &--transparent{
        background-color: transparent;
        box-shadow: none;
        padding:0;
    }
    &--shadow{
        box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.26);
    }
    &--last{
        margin-bottom: 0;
    }
}