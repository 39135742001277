@import 'scss/index';
@import 'scss/mixins';

.create-fact{

  &__list{
    @include display-flex(flex);
    @include flex-wrap(wrap);
  }  

  &__col {
    flex: 0 0 50%;
    max-width: 50%;
    box-sizing: border-box;
    padding: 15px;

    &--search {
      padding: 7px;
      border: 1px solid var(--disable-color);
      border-radius: 6px;    
      input {
        border: none;
        color: var(--text-on-primary);
        margin-left: 10px;
      }
    }

    &:first-child {
      border: 1px solid var(--disable-color);
      border-radius: 4px;
      padding: 5px;
    }
    h3 {
      font-size: calc(var(--body-font-size) + 2px);
      padding-bottom: 20px;
      font-weight: 600;
    }
    p {
      font-size: var(--body-font-size);
      line-height: 18px;
      word-break: break-all;
      &:not(:last-child) {
        padding-bottom: 30px;
      }
    }
    ul {
      max-height: 135px;
      overflow-y: auto;
      margin-right: -5px;
      padding-right: 5px;
  
      &::-webkit-scrollbar-track {
        border-radius: 2px;
      }
  
      &::-webkit-scrollbar {
        width: 2px;
      }
  
      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--text-on-teritary);
      }
      li {
        @include display-flex(flex);
        @include justify-content(space-between);
        @include align-items(center);
        color: var(--text-on-primary);
        border: 1px solid var(--disable-color);
        padding: 7px;
        margin: 4px 0;
        border-radius: 6px;
        font-size: calc(var(--body-font-size) - 2px);
        cursor: pointer;
      }
    }
  }

  &__button {
    @include display-flex(flex);
    @include flex-wrap(wrap);
    @include justify-content(flex-end);
    button{
      margin: 0 5px;
    }
  }
  
}

.shrink {
  @include position(absolute);
  color: var(--primary-color);
  padding: 10px;
  cursor: pointer;
}

.expand {
  color: var(--primary-color);
  padding-bottom: 10px;
  cursor: pointer;
}
   
.selected {
  border: 1px solid var(--primary-color) !important;
}

.formula{
  @include width-height(-webkit-fill-available, 90px);
  border: 1px solid var(--text-on-teritary);
  border-radius: 4px;
  color: var(--text-on-primary);
  font-family:  var(--font-family);
  padding: 10px;
  resize: none;
  margin-bottom: 10px;
}

.react-autocomplete-input{
    border-radius: 4px !important;
    li{
      border-bottom: 1px solid var(--disable-color) !important;
      padding: 2px 5px !important;
    } 
}

  