.group-container {
  margin-left: 34px;
  display: flex;
  flex-direction: column;
}

.group {
  position: relative;
  margin: 12px 0px 0px 27px;
  padding: 12px;
  border-radius: 4px;
  border: 1px solid #c7c9d7;
}

.remove-group,
.drag-container {
  background-color: transparent;
  border: none;
  vertical-align: middle;
  margin: 0 0 0 10px;
  cursor: pointer;
}

.drag-handler {
    width: 18px;
    height: 18px;
  }


  .rule-definition > .group > .options .remove-group,
  .rule-definition > .group > .options .drag-handler {
    display: none;
  }
  
  .rule-definition > .group {
    margin: 0px;
  }

  .group .rule::after,
  .group .rule::before,
  .group-container .group::after,
  .group-container .group::before {
    border-color: #adadad;
  }
  
  .group-container .group::after,
  .group-container .group::before {
    content: "";
    position: absolute;
    left: -34px;
    width: 33px;
    height: calc(50% + 11px);
    border-style: solid;
    border-color: #c6c9d8;
  }

   
  .group-container .group::before {
    top: -11px;
    border-width: 0 0 1px 1px;
  }
  
  .group-container .group::after {
    top: calc(50%);
    border-width: 0 0 0 1px;
  }
  
  .group-container .group:first-child::before {
    top: -21px;
    height: calc(50% + 21px);
  }
  
  .group-container .group:last-child::before {
    border-radius: 0 0 0 0px;
  }
  
  .group-container .group:last-child::after {
    display: none;
  }
  
  .group .rule::after,
  .group .rule::before {
    content: "";
    position: absolute;
    left: -16px;
    width: 29px;
    height: calc(50% + 18px);
    border-style: solid;
    border-color: #c7c9d7;
  }
  
  .group .rule::before {
    top: -13px;
    border-width: 0 0 1px 1px;
  }
  
  .group .rule::after {
    top: calc(50%);
    border-width: 0 0 0 1px;
  }
  
  .group .rule:first-child::before {
    top: -11px;
    height: calc(50% + 18px);
  }
  
  .group .rule:last-child::before {
    border-radius: 0 0 0 0px;
  }
  
  .group .rule:last-child::after {
    display: none;
  }
  
  .group .dragging {
    position: fixed;
    opacity: 0.5;
    z-index: 100;
  }
  
  .group .rule-placeholder {
    position: relative;
    margin: 4px 0;
    border-radius: 5px;
    padding: 5px;
    background: rgba(255, 255, 255, 0.9);
    border: 1px dashed #bbb;
    opacity: 0.7;
  }

  .hook {
    margin: 0 5px;
    cursor: pointer;
    height: 36px;
    padding: 10px 12px;
    border-radius: 4px;
    border: solid 1px #c6c9d8;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
    color: #333860;
  }

  .insert-group:disabled,
  .insert-rule:disabled {
    color: #c5c9d9 
  }
  
