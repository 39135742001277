@import 'src/scss/mixins';


.aside-bar{
    --header: 60px;
    --footer:76px;
    --total: calc(var(--header) + var(--footer));
    height: calc(100vh - var(--total));
    background-color: var(--white-color);
    padding-top: 20px;
    overflow-y: scroll;
}