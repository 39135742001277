@import 'src/scss/mixins';

.creat-new{
    @include display-flex(flex);
    @include align-items(center);
    @include justify-content(center);
    height: calc(100vh - 286px);
    text-align: center;
    img{
        margin-bottom: 5px;
    }
    h2 {
        padding-bottom: 5px;
        line-height: 18px;
    }
    p{
        padding-bottom: 10px;
    }
}