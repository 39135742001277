@import 'src/scss/mixins';

.search {
    background-color: var(--white-color);
    border-radius: 4px;
    cursor: pointer;
    color: var(--text-on-primary);
    font-size: var(--body-font-size);    
    font-weight: var(--weight-regular);
    position: relative;
    margin-bottom: 15px;
    width: 300px;
    &--last{
       margin-bottom: 0;
    }
    &__wrap{
       align-items: center;
       box-shadow: 0 1px 2px 0 rgba(0, 114, 253, 0.16);
       box-sizing: border-box;
       display: flex;
       position: relative;
       padding-left: 35px;     
    }
    &__input {
       @include width-height(100%, 45px);
       background-color: transparent;
       border: none;
       box-sizing : border-box;
       color: var(--text-on-primary);
       outline: none;
    }
    &__icon {
       @include position(absolute, 10px, null, null, 10px);
       @include width-height(15px, 15px);
    }
 }
 