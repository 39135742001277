@import 'scss/index';
@import 'scss/mixins';

.modal{
    @include position(absolute);
    background: var(--white-color);
    box-shadow: 0 3px 20px 0 rgba(0, 0, 0, 0.16);
    left: 50%;
    padding: 15px;
    width: 280px;
    max-width: max-content;
    top: 30px;
    transform: translateX(-50%);
    z-index: 999;

    &__textbox{
        height: 20px !important;
    }
    &__textarea{
        height: 100px !important;
    }
    &__row {
        @include display-flex(flex);
        @include flex-wrap(nowrap);
        @include justify-content(space-around);
    }
}


  