@import 'scss/_mixins.scss';

.textarea-row {
    @include position(relative);
    margin-bottom: 15px;
    label {
        color: var(--text-on-primary);
        display: block;
        font-size: calc(var(--body-font-size) - 2px);
        padding-bottom: 8px;
        line-height: var(--body-font-size);
        font-weight: var(--weight-medium);

        sup {
            color: var(--error-color);
            position: absolute;
            font-size: calc(var(--body-font-size) + 1px);
        }
    }

    &--last {
        margin-bottom: 0;
    }

    &__input {
        @include width-height(var(--fluid-width), 96px);
        background-color: transparent;
        border: 1px solid var(--text-on-teritary);
        border-radius: 4px;
        font-family: var(--font-family);
        padding: 10px;
        resize: none;
        &:focus {
            border: 1px solid var(--primary-color);
            outline: none;
        }
    }
}