@import 'src/scss/mixins';

.status{
   color: var(--text-on-primary);
   font-size: var(--body-font-size);
   font-weight: var(--weight-medium);
   padding-bottom: 10px;
   &__input{
        @include display-flex(inline-flex);
        input{
            @include width-height(24px, 24px);
        }
        label{
            padding: 0 12px;
        }
        .inactive{
            color: var(--text-on-teritary);
        }
    }
}