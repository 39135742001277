@import 'src/scss/mixins';

.bread-crumbs{
    @include display-flex(inline-flex);
    @include flex-wrap(wrap);
    border-radius: 4px;
    padding: 10px 0;
    li{
        @include display-flex(flex);
        @include align-items(center);
        font-size: var(--body-font-size);
        line-height: var(--body-font-size);
        margin-right: 8px;
        position: relative;
        padding-right: 14px;
        text-transform: capitalize;
        color: var(--text-on-teritary);
        font-weight: var(--weight-semi-bold);
        &:after{
            @include position(absolute, null, 0, null, null);
            content: '\003E';
            font-size: calc(var(--body-font-size));
            color: var(--text-on-teritary);
            line-height: calc(var(--body-font-size) + 10px);
       }
       &:last-child{
           padding-right: 0;
           margin-right: 0;
           color: var(--text-on-primary);
           &:after{
               display: none;
           }
        }
    }
}