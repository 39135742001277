@import 'src/scss/mixins';

.button{
    background-color: transparent;
    border: none;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    font-size: var(--body-font-size);
    font-weight: var(--weight-normal);
    height: 45px;
    letter-spacing: 0.5px;
    min-width: 140px;
    outline: none;
    padding: 9px;
    text-align: center;
    text-transform: capitalize;
    &:focus{
        box-shadow: 0 0 10px rgba(0, 114, 253, 0.6);
    }
    &--xsmall{
        min-width: 90px;
    }
    &--compact{
        min-width: 104px;
        height: 36px;
    }
    &--small{
        min-width: 140px;
    }
    &--medium{
        min-width: 200px;
    }
    &--full{
        min-width: var(--fluid-width);
    }
    &--primary, &--secondary{
        color: var(--white-color);
    }
    &--primary{
        background-color: var(--primary-color);
        border: 1px solid var(--primary-color);
    }
    &--secondary{
        background-color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
        &:focus{
            box-shadow: 0 0 10px rgba(253, 139, 0, 1);
        }
    }
    &--transparent, &--ghost{
        background-color: transparent;
        color: var(--primary-color);
    }
    &--ghost{
        border: none;
        padding: 0px;
        text-align: center;
        &:focus{
            box-shadow: none;
            text-shadow: 0 0 10px rgba(0, 114, 253, 0.6);
        }
    }
    &--disabled{
        background-color: var(--disable-color);
        border: none;
        cursor: not-allowed;
    }
    &__image{
        @include width-height(15px, 15px); 
        display: inline-block;
        margin: -3px 10px 0 0;
        vertical-align: text-top;
    }
    &--right{
        text-align: right !important;
    }
}

.button.button--primary.button--transparent, 
.button.button--primary.button--ghost{
    color: var(--primary-color);
}

.button.button--secondary.button--transparent, 
.button.button--secondary.button--ghost{
    color: var(--secondary-color);
}

.button.button--primary.button--transparent.button--disabled, 
.button.button--secondary.button--transparent.button--disabled{
        background-color: transparent !important;
        border: 1px solid var(--primary-color) !important;
        cursor: not-allowed;
        opacity: 0.5 ;
}

.button.button--ghost.button--disabled{   
    background-color: transparent; 
    color: var(--disable-color);
}
