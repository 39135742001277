@import "./reset";
@import "./variables";
@import "./typo";
@import "./common";
@import "./grid";
@import "./datepicker";
@import "./selectmenu";
@import "./grid";

body {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    touch-action: manipulation;
    -webkit-user-drag: none;
    -ms-content-zooming: none;
    overscroll-behavior-y: none;
    text-size-adjust: none;
}

*, *:before, *:after {
    box-sizing: border-box;
  }

body, h1, h2, h3, h4, h5, h6, ul, li, p{
    margin: 0;
}

li{
    list-style: none;
}

ul{
    padding: 0;
}

textarea{
    font-family: var(--font-family);
    resize: none;
}

.ddcontainerghost{
    transition: cubic-bezier(0.075, 0.82, 0.165, 1);
}
.ddcontainer{
    width: 100%;
}

.droptarget{
    display: block;
    min-height: 10px;
    .card__col{
        animation: scale 0.5s;
    }
}
.droptarget.highlighted{
    background-color: var(--strip-bg);
    border-radius: 4px;
    padding: 15px;
    text-align: center;
}

input:disabled, textarea:disabled{
  opacity: 0.8;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    font-size: var(--body-font-size);
    color: var(--disable-color);
  }
  
  ::-moz-placeholder {
    /* Firefox 19+ */
    font-size: var(--body-font-size);
    color: var(--disable-color);
  }
  
  :-ms-input-placeholder {
    /* IE 10+ */
    font-size: var(--body-font-size);
    color: var(--disable-color);
  }
  
  :-moz-placeholder {
    /* Firefox 18- */
    font-size: var(--body-font-size);
    color: var(--disable-color);
  }
  
  input:disabled::-webkit-input-placeholder {
    /* WebKit browsers */
    color: var(--disable-color);
  }
  
  input:disabled:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: var(--disable-color);
  }
  
  input:disabled::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: var(--disable-color);
  }
  
  input:disabled:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: var(--disable-color);
  }

@keyframes scale {
    from {transform: scale(0.9);}
    to {transform: scale(1);}
}

.envelope__factlist .ddcontainer {
  display: block !important;
}

.envelope__factlist .ddcontainerghost {
  width: 240px;
}

.ddcontainerghost{
  max-width: 280px;
}

.select-child__menu {
  overflow-y: hidden;
  max-height: 250px;
}

.css-b62m3t-container .css-1f43avz-a11yText-A11yText {
  display: none;
}

.css-b62m3t-container .css-7pg0cj-a11yText {
  display: none;
}

.scroll-bar {
  overflow-y: auto;
  overflow-x: hidden;
  &::-webkit-scrollbar-track {
      border-radius: 3px;
      background-color: transparent;
  }

  &::-webkit-scrollbar {
      width: 3px;
      background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--border-color);
  }
}

.react-switch-handle{
  width: 15px !important;
  height: 15px !important;
  top: 1.5px !important;
}

.react-switch-bg{
  width: 28px !important;
}

.react-daterange-picker__wrapper{
  height: 45px;
  border: none !important;
  padding: 0 10px;
}

.datepicker-row--disabled {
  .react-date-picker__clear-button{
    display: none;
  }
}

.select-menu--disabled{
  .select-child__indicators{
    display: none !important;
  }
}
