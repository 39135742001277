
.layout-wrap{
    position: relative;
    padding-left: 60px;
    &__container{
        padding:20px 25px;
        min-height: calc(100vh - 60px);
    }
     
    &--bottom {
        padding-bottom: 80px;
    }
}

@media screen and (min-width:1367px) {
    .layout-wrap{
        margin: 0 auto;
        max-width: 1366px;
        width: 100%;
    }
}