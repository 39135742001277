
.react-date-picker{
    background-color: var(--white-color);
    width: 100%;
    height: 45px;
    &__wrapper{
        border-radius: 4px;
        border-color: var(--text-on-teritary);
    }
}

.react-calendar{
    border-radius: 4px;
        border-color: var(--text-on-teritary);
}

.react-calendar__tile--now:enabled:hover, .react-calendar__tile--now:enabled:focus, .react-calendar__tile--now{
    background: var(--primary-color);
    color: var(--white-color);
}

.react-date-picker__inputGroup{
    padding: 0 10px;
}
.react-date-picker__button svg {
    stroke: var(--text-on-teritary);
    cursor: pointer;
    width: 15px !important;
    height: 15px !important;
}

.react-date-picker__clear-button {
    position: relative;
    right: 28px;
}

.react-date-picker__calendar-button {
    display: none;
}

.react-date-picker__inputGroup__input:invalid{
    background: transparent;
}