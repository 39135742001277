@import 'src/scss/mixins';

.title-wrap {
  position: relative;
  padding-bottom: 15px;
  &__title {
    h2 {
      @include display-flex(flex);
      @include align-items(center);
      position: relative;
      width: max-content;

      img {
        cursor: pointer;
      }

      span {
        background-color: var(--secondary-color);
        border-radius: 4px;
        color: var(--white-color);
        display: inline-block;
        font-size: calc(var(--body-font-size) - 4px);
        height: 20px;
        line-height: 21px;
        margin: 0 8px;
        min-width: 20px;
        padding: 0 3px;
        vertical-align: middle;
        text-align: center;
      }

      ul {
        @include position(absolute, 30px, -65px, null, null);
        box-sizing: border-box;
        border: 1px solid rgba(0, 0, 0, .15);
        border-radius: 4px;
        background: var(--white-color);
        box-shadow: 0 0 8px 0 #ccdafa;
        max-height: 245px;
        width: max-content;
        z-index: 999999999;

        li {
          color: var(--text-on-primary);
          font-size: calc(var(--body-font-size) - 2px) !important;
          line-height: var(--body-font-size) !important;
          padding: 10px !important;
          border-bottom: 1px solid var(--border-color);
          margin-bottom: 0 !important;
          outline: none;
          cursor: pointer;
          display: block;
          position: relative;
        }

        .active {
          color: var(--primary-color);
          font-weight: var(--weight-bold);
        }
      }
    }
  }

  &__drop {
    @include position(absolute, 90px, null, null, 220px);
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, .15);
    border-radius: 4px;
    background: var(--white-color);
    box-shadow: 0 0 8px 0 #ccdafa;
    padding: 10px;
    max-height: 245px;
    width: 120px;
    z-index: 999999999;

    li {
      color: var(--text-on-primary);
      font-size: calc(var(--body-font-size) - 4px);
      line-height: var(--body-font-size);
      padding: 10px;
      outline: none;
      cursor: pointer;
      display: block;
    }

    .active {
      color: var(--primary-color);
      // font-weight: $font-weight-semi-bold;
    }
  }

  &__row {
    @include display-flex(flex);
    @include justify-content(space-between);
    @include align-items(center);
  }

  &--end {
    @include justify-content(flex-end);
  }

  &--start {
    @include justify-content(flex-start);
  }

  &__tab {
    @include display-flex(flex);
    margin: 0 -10px;

    li {
      @include display-flex(flex);
      color: var(--text-on-teritary);
      cursor: pointer;
      font-size: calc(var(--body-font-size) + 2px);
      font-weight: var(--weight-medium);
      padding: 0 10px;
      vertical-align: top;
      transition: all 0.3s ease;

      &.is-active {
        color: var(--text-on-primary);
      }

      img{
        padding-left: 5px;
      }
    }
  }

  &__search {
    @include display-flex(flex);

    Button {
      margin-left: 10px;
    }
  }
}
