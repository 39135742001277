@import 'src/scss/mixins';

.tab-row {
    @include display-flex(flex);
    border-bottom: 1px solid var(--disable-color);
    margin-bottom: 20px;
    width: 100%;
    li {
        border-bottom: 2px solid transparent;
        cursor: pointer;
        color: var(--disable-color);
        display: inline-block;
        font-size: calc(var(--body-font-size) + 2px);
        font-weight: var(--weight-medium);
        padding: 12px 0;
        margin-right: 30px;
        &.is-active {
            color: var(--text-on-primary);
            border-bottom: 3px solid var(--primary-color);
        }
    }
}
