@import 'scss/mixins';

.datepicker-row{
    margin-bottom: 15px;
    &--last{
        margin-bottom: 0;
    }
    label {
        color: var(--text-on-primary);
        display: block;
        font-size: calc(var(--body-font-size) - 2px);
        padding-bottom: 8px;
        line-height: var(--body-font-size);
        font-weight: var(--weight-medium);
        sup {
            color: var(--error-color);
            position: absolute;
            font-size: calc(var(--body-font-size) + 1px);
        }
    }
    &__block{
        @include display-flex(flex);
        @include justify-content(flex-end);
        @include align-items(center);
        position: relative;
        img{
            @include position(absolute, null, 8px, null, null);
            @include width-height(20px, 20px);
        }
    }
}