.logic {
    padding: 5px;
    position: relative;
    z-index: 3;
    width: 103px;
    height: 36px;
    margin: 0px 5px;
    border-radius: 4px;
    border: solid 1px #c6c9d8;
    background-color: #ffffff;
    font-size: 14px;
    font-weight: 500;
  }
  
  .drag-box {
    padding-left: 16px;
    width: 220px;
    height: 36px;
    margin: 0 0 0 2px;
    border-radius: 4px;
    border: solid 1px #c6c9d8;
    background-color: #ffffff;
    font-size: 12px;
    color: #7c85a3;
    outline: none;
  
    &::placeholder {
      font-size: 12px;
    }
  }
  
  .dropdown {
    margin: 0 9px;
    width: 180px;
    height: 36px;
    border-radius: 4px;
    border: solid 1px #c6c9d8;
    background-color: #ffffff;
    font-size: 12px;
    padding-left: 16px;
    outline: none;
    color: #7c85a3;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
  }
  
  select.dropdown {
    background-image: linear-gradient(45deg, transparent 50%, #c5c9d9 50%),
      linear-gradient(135deg, #c5c9d9 50%, transparent 50%);
    background-position: calc(100% - 20px) calc(1em + 2px), calc(100% - 15px) calc(1em + 2px), 100% 0;
    background-size: 5px 5px, 5px 5px, 2.5em 2.5em;
    background-repeat: no-repeat;
  }
  
  .value-box{
    display: inline-block;
    border: solid 1px #c6c9d8;
    background-color: #ffffff;
    border-radius: 4px;
    width: 200px;
    img{
      width: 18px;
      height: 18px;
      vertical-align: middle;
      cursor: pointer;
    }
  }
  
  .value {
    height: 36px;
    width: 170px;
    font-size: 12px;
    border: none;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    padding: 0 10px;
    outline: none;
    color: #7c85a3;
  }

  .remove-rule,
  .drag-container {
    background-color: transparent;
    border: none;
    vertical-align: middle;
    margin: 0 0 0 10px;
    cursor: pointer;
  }
  
  .remove-rule img {
    width: 18px;
    height: 18px;
  }
  
  .rule {
    position: relative;
    padding: 12px 12px 0;
    margin: 0px 10px 0px;
    background-color: #ffffff;
  }
  
  .rule select {
    margin: 0 9px;
  }
  
  .hide {
    display: none;
  }
  
  .show-group {
    display: inline-flex;
    flex-direction: column;
  }
  
  .show-rule {
    display: inline-block;
    flex-direction: column;
  }