@import 'src/scss/mixins';

.button-section {
    @include position(fixed, null, 0, 0, 0);
    @include display-flex(flex);
    @include justify-content(flex-end);
    background-color: var(--white-color);
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.08);
    padding: 15px 35px;
    margin: 0 auto;
    z-index: 2;
    max-width: 1366px;
    width: 100%;
}
