@import 'scss/index';
@import 'scss/mixins';

.fact-detail {
    display: grid;
    grid-template-columns: auto auto;
    grid-gap: 0 10px;
}

.generic-detail {
    @include display-flex(flex);
    @include align-items(center);
    padding-bottom: 10px;
    span{
        padding-left: 5px;
    }
    input {
        cursor: pointer;
    }
}