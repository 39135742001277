.select-menu {
    margin-bottom: 15px;
    label {
        color: var(--text-on-primary);
        display: block;
        font-size: calc(var(--body-font-size) - 2px);
        padding-bottom: 8px;
        line-height: var(--body-font-size);
        font-weight: var(--weight-medium);
        sup {
            color: var(--error-color);
            position: absolute;
            font-size: calc(var(--body-font-size) + 1px);
        }
    }
}

.select-child__control {
    min-height: 45px !important;
    border: 1px solid var(--text-on-teritary) !important;
}