@import 'scss/mixins';

.wrapper {
  color: var(--text-on-primary);
  background-color: var(--white-color);
  border: solid 1px var(--text-on-teritary);
  border-radius: 5px;
  box-sizing: border-box;
  font-size: calc(var(--body-font-size) - 2px);
  line-height: 14px;

  &__header {
    @include display-flex(flex);
    @include justify-content(space-between);
    @include align-items(center);
    border-bottom: solid 1px var(--text-on-teritary);
    margin-bottom: 15px;
    padding: 10px;
    h4, span {
      color: var(--text-on-secondary);
      padding: 0;
      font-weight: 600;
    }
    span {
      text-decoration: underline;
      cursor: pointer;
      position: relative;
    }
  }
}

.value input[type="text"]::-webkit-input-placeholder,
.drag-box input[type="text"]::-webkit-input-placeholder {
  font-size: calc(var(--body-font-size) - 2px);
  color: var(--disable-color) !important;
}



