@import 'src/scss/mixins';

.side-bar{
    @include position(fixed, 0, null, 0, 0);
    background-color: var(--primary-color);
    padding: 10px 0;
    width: 60px;
    z-index: 3;
    li{
        cursor: pointer;
        position: relative;
        text-align: center;
        width: 100%;
        &:before{
            @include position(absolute, 50%, null, null, 0);
            @include width-height(0px, 50%);
            background-color: var(--nav-hover);
            border-radius: 4px;
            content: '';
            transition: all 0.3s ease-in;
            transform: translateY(-50%);
        }
        a{
            display: block;
        }
        span, samp{
            color: var(--white-color);
        }
        samp{
            font-size: calc(var(--body-font-size) + 12px);
            line-height: 50px;
            position: relative;
            z-index: 3;
        }
        span{
            @include position(absolute, 50%, null, null, null);
            @include translate(-10px, -50%);
            @include opacity-visibility(0, hidden);
            background-color: var(--secondary-color);
            border-radius: 4px;
            font-size: calc(var(--body-font-size) - 2px);
            line-height: var(--body-font-size);
            padding: 5px;
            transition: all 0.3s ease-in;
            &:before{
                @include position(absolute, 50%, null, null, -5px);
                @include width-height(0,0);
                border-top: 5px solid transparent;
                border-right: 5px solid var(--secondary-color);
                border-bottom: 5px solid transparent;
                content: "";
                transform: translateY(-50%);
            }
        }
        &:hover{
            &:before{
                width: 3px;
            }
            span{
                @include opacity-visibility(1, visible);
                @include translate(-6px, -50%);
            }
        }
    }
}

.logo{
    width: 45px;
    margin: 0 auto;
}

@media screen and (min-width:1367px) {
    .side-bar{
        position: absolute;
    }
}