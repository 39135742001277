@import 'scss/_mixins.scss';

.no-data {
    @include width-height(100%, calc(100vh - 120px));
    @include display-flex(flex);
    @include justify-content(center);
    @include align-items(center);
    img {
        @include width-height(180px, 180px);
    }
    &__content {
        width: 380px;
        text-align: center;
        h6 {
            font-size: calc(var(--body-font-size) + 6px);
            font-weight: var(--weight-semi-bold);
        }
        p {
            padding: 8px;
            font-weight: var(--weight-semi-bold);
            font-size: 16px;
            color: var(--text-on-secondary);
        }
    }
}