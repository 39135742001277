@import 'scss/_mixins.scss';

.textbox-row {
    @include position(relative);
    margin-bottom: 15px;
    
    label {
        color: var(--text-on-primary);
        display: block;
        font-size: calc(var(--body-font-size) - 2px);
        padding-bottom: 8px;
        line-height: var(--body-font-size);
        font-weight: var(--weight-medium);
        sup {
            color: var(--error-color);
            position: absolute;
            font-size: calc(var(--body-font-size) + 1px);
        }
    }

    &--last {
        margin-bottom: 0;
    }

    &__input {
        @include width-height(var(--fluid-width), 45px);
        background-color: transparent;
        border: 1px solid var(--text-on-teritary);
        border-radius: 4px;
        font-family: var(--font-family);
        padding: 10px;

        &:focus {
            border: 1px solid var(--primary-color);
            outline: none;
        }
    }

    &--error {
        border: 1px solid var(--error-color);
    }

    &--readonly {
        input {
            border: 1px solid var(--disable-color);
            color: var(--text-on-teritary);
            outline: none;
        }

        label {
            color: var(--text-on-teritary);
        }
    }

    &__error {
        color: var(--error-color);
        font-size: calc(var(--body-font-size) - 4px);
        line-height: calc(var(--body-font-size) - 2px);
        padding-top: 5px;
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: inherit;
    font-size: inherit;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
    font-size: inherit;
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: inherit;
    font-size: inherit;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
    font-size: inherit;
}

input:disabled::-webkit-input-placeholder {
    /* WebKit browsers */
    color: inherit;
}

input:disabled:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: inherit;
}

input:disabled::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: inherit;
}

input:disabled:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    color: inherit;
}