h1,
h2 {
    line-height: 30px;
    padding-bottom: 20px;
}

h3,
h4 {
    line-height: 25px;
}

h1 {
    font-size: 24px;
    font-weight: var(--weight-bold);
}

h2 {
    font-size: 20px;
}

h3 {
    font-size: 18px;
    font-weight: var(--weight-medium);
    padding-bottom: 15px;
}

h4 {
    font-size: 16px;
}

h2,
h4,
h5,
h6 {
    font-weight: var(--weight-medium);
}

h4,
h5,
h6 {
    padding-bottom: 10px;
}

h5 {
    font-size: 14px;
    line-height: 15px;
}

h6 {
    font-size: 12px;
    line-height: 15px;
}
