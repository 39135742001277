@import 'src/scss/mixins';

.landing-wrap{
    @include position(fixed, 0,0,0,0);
    @include display-flex(flex);
    @include align-items(center);
    @include justify-content(center);
    background-color: #f5f6f6;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center bottom;
    padding: 40px;
    z-index: 9;
    &__container{
        max-width: 1366px;
        margin: 0 auto;
        width: 100%;
    }
    h1{
        color: #1c1c1c;
        font-size: 36px;
        padding-bottom: 5px;
        line-height: 43px;
        span{
            color: #c70152;
        }
    }

    p{
        color: #272727;
        font-size: calc(var(--body-font-size) + 2px);
        padding-bottom: 20px;
    }

    &__row{
        @include align-items(center);
        @include justify-content(center);
    }

    img{
        width: 100%;
    }
    button{
        background-image: linear-gradient(103deg, #99068b 1%, #d00047 108%);
        border-radius: 4px;
        border: none;
        color: var(--white-color);
        min-width: 220px;
        padding: 10px;
    }
}