@import 'src/scss/mixins';

.popup{
    @include position(fixed, 0, null, null, 0);
    @include display-flex(flex);
    @include align-items(center);
    @include justify-content(center);
    @include width-height(var(--fluid-width), var(--fluid-width));
    background-color: rgba(0,0,0, 0.5);
    z-index: 999;
    &__inner{
      background-color: var(--white-color);
      border-radius: 4px;
      position: relative;
      width: var(--fluid-width);
    }
    &--left{
      text-align: left !important;
    }
    &__content{
      box-sizing: border-box;
      overflow-y: auto;
      text-align: center;
      padding:25px 25px;
    }
    &--small{
      max-width: 340px;
    }
    &--xsmall{
     max-width: 275px;  
    }
    &--medium{
      max-width: 580px;
    }
    &--compact{
      max-width: 450px;
    }
    &--large{
      max-width: 980px;
    }
    
  h2{
    font-weight: var(--weight-bold) !important;
    padding: 10px 0;
  }
  p{
    padding-bottom: 10px;
    line-height: 18px;
  }
}