@import 'src/scss/mixins';

.alert{
  position: fixed;
  transition: all .3s ease;
  width: var(--fluid-width);
  z-index: 999;
  bottom: 30px;
  left: 0;
  &--top{
    top: 30px;
    bottom: unset;
  }
  &__message{
    background-color: var(--white-color);
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    box-sizing: border-box;
    min-width: 450px;
    max-width: fit-content;
    padding: 10px 60px;
    position: relative;
    width: 100%;
    min-height: 60px;
    z-index: 9999;
    --window: 100vw;
    --container: 1366px;
    --halfWidth: calc((var(--window) - var(--container)) / 2 + 70px);
    left: var(--halfWidth);
    &--center{
      margin: 0 auto;
      left: unset;
    }
    &--info{
      border-left: 5px solid var(--primary-color);
    }
    &--error{
      border-left: 5px solid var(--error-color);
    }
    &--success{
      border-left: 5px solid var(--success-color);
    }
    &--warning{
      border-left: 5px solid var(--warning-color);
    }
    &__icon, &__close, &__undo{
      @include width-height(60px, 60px);
      line-height: 60px;
      position: absolute;
      transform: translateY(-50%);
      top:50%;
      text-align: center;
    }
    &__icon{
      left: 0;
      img{
        vertical-align: middle;
      }
    }
    &__close{
      cursor: pointer;
      right: 0;
      top: 90%;
    }
    h2, p{
      font-family: var(--font-family);
      font-size: var(--body-font-size);
      color: var(--text-on-primary);
      margin: 0;
    }
    p{ 
      font-weight: var(--weight-regular);
      padding: 0;
    }
    h2{
      font-size: calc(var(--body-font-size) + 4px);
      line-height: calc(var(--body-font-size) + 4px);
      padding-bottom: 5px;
    }
  }
}