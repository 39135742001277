@import 'src/scss/mixins';

.create-popup{
    @include position(fixed, 0, null, null, 0);
    @include display-flex(flex);
    @include align-items(center);
    @include justify-content(center);
    @include width-height(100%, 100%);
    background-color: rgba(0,0,0, 0.5);
    z-index: 999;
    &__inner{
      @include width-height(450px, max-content);
      background-color: var(--white-color);
      border-radius: 4px;
      position: relative;
      padding:25px;
    }
    &__details{
      box-sizing: border-box;
      padding: 0 1px;
      overflow-y: auto;
      text-align: left;
    }
    &__button {
      @include display-flex(flex);
      @include justify-content(flex-end);
      padding-top: 10px;
    }
}

