@import 'src/scss/mixins';

.search-row{
   background-color: var(--white-color);
   box-shadow: 0 1px 2px 0 rgba(0, 112, 253, 0.08);
   border-radius: 4px;
   cursor: pointer;
   color: var(--text-on-primary);
   font-size: var(--body-font-size);    
   font-weight: var(--weight-regular);
   position: relative;
   padding-left: 30px;
   min-width: 300px;
   &__show{
       @include position(absolute, 0, 0, 0, 0);
       z-index: 9;
   }
   &__input {
       @include width-height(240px, 45px);
       background-color: transparent;
       border: none;
       color: var(--text-on-primary);
       padding: 10px;
       outline: none;
       text-transform: capitalize;
   }
   &__reset{
       @include position(absolute, null, 15px, null, null);
       transform: translateY(-6px);
       color:var(--text-on-primary);
       font-size: calc(var(--body-font-size) - 2px); 
       line-height: var(--body-font-size);
       font-weight: var(--weight-medium);
   }
   &__icon {
       @include position(absolute, 15px, null, null, 12px);
       @include width-height(16px, 16px);
   }
   &__result{
       @include position(absolute, 55px, null, null, 0);
       @include width-height(var(--fluid-width), null);
       max-height: 270px;
       background-color: var(--white-color);
       box-shadow: 0 1px 2px 0 rgba(0, 112, 253, 0.08);
       overflow-x: auto;
       overflow-y: auto;
       border-radius: 5px;
       z-index: 99;
   }
   &__list{
       list-style: none;
       margin: 0;
   }
   &__item{
       color: var(--text-on-primary);
       display: block;
       padding:10px 15px;
       text-align: left;
       border-bottom: 1px solid #e2f2ff;
       font-size: calc(var(--body-font-size) - 2px); 
       line-height: calc(var(--body-font-size) + 2px);
       font-weight: var(--weight-regular);
       word-break: break-word;
       text-transform: capitalize;
       span{
           @include position(absolute, null, 15px, null, null);
           transform: translateY(-20px);
       }
   }
   &__item:hover{
       color: var( --primary-color);
   }
   &__selected {
       color: var(--primary-color);
       display: block;
       padding: 15px;
       text-align: left;
       font-size: calc(var(--body-font-size) - 2px);
       line-height: var(--body-font-size);
       font-weight: var(--weight-medium);
       border-bottom: 1px solid var(--bg-color);
   }
}

.chips{
    background-color: var(--disable-color);
    border: none;
    border-radius: 20px;
    color: var(--white-color);
    font-size: calc(var(--body-font-size) - 2px);
    line-height: calc(var(--body-font-size) - 2px);
    cursor: pointer;
    display: inline-block;
    font-weight: var(--weight-regular);
    margin:0 2px 5px;
    padding: 6px 10px;
    text-align: center;  
    &__label,&__close{
        display: inline-block;
        line-height: 18px;
    } 
    &__close{
        @include width-height(15px, 15px); 
        @include position(relative, 2px, null, null, null);
        font-size: calc(var(--body-font-size) + 6px);
        font-weight: var(--weight-regular);
        line-height: 15px;
        cursor: pointer;
        margin: 0 3px;
    }
}


 