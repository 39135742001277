@import 'src/scss/mixins';

.priviledges{
    @include position(fixed, 0,0,0,0);
    @include display-flex(flex);
    @include justify-content(center);
    @include flex-direction(column);
    @include align-items(center);
    background-color: #f5f6f6;
    padding: 40px;
    z-index: 9;
    min-height: 100vh;
    h2{
        padding-bottom: 10px;
    }
    &__content{
        max-width: 480px;
        text-align: center;
        width: 100%;
    }
    p{
        padding-bottom: 25px;
    }
    &__button{
        border-radius: 5px;
        border: 1px solid #c20259;
        background-image: linear-gradient(103deg, #99068b 1%, #d00047 108%);
        color: var(--white-color);
        cursor: pointer;
        font-weight: 700;
        display: inline-block;
        height: 40px;
        padding: 10px;
        line-height: 19px;
        text-align: center;
    }
}

