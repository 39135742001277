@import 'src/scss/mixins';

.loader{
    @include position(fixed, 0, null, null, 0);
    @include display-flex(flex);
    @include align-items(center);
    @include justify-content(center);
    @include width-height(var(--fluid-width), var(--fluid-width));
    @include opacity-visibility(0, hidden);
    background-color: rgba(255,255,255, 0.4);
    transition: all 0.3s ease;
    z-index: 99;
    &__spinner{
        border: 5px solid var(--primary-color);
        border-radius: 50%;
        border-top: 5px solid var(--disable-color);
        @include width-height(35px, 35px);
        -webkit-animation: spin 2s linear infinite; /* Safari */
        animation: spin 2s linear infinite;
    }
    &__label{
        padding-left: 10px;
    }
    &--active{
        @include opacity-visibility(1, visible);
    }
}

@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}