@import 'src/scss/mixins';

.matrix{
    position: relative;
    min-height: 350px;
    overflow-x: auto;
    &::-webkit-scrollbar-track {
        border-radius: 3px;
    }
    &::-webkit-scrollbar {
        width: 3px;
        height: 3px;
    }
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: var(--border-color);
    }
    &__header{
        @include display-flex(flex);
        @include justify-content(space-between);
        background-color: var(--strip-bg);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
        padding: 15px;
        &.fixed{
            position: sticky;
            left: 0;
            z-index: 3;
        }
        h4{
            width: 50%;
            font-size: calc(var(--body-font-size) + 3px);
            padding-bottom: 0;
        }
        img {
            cursor: pointer;
        }
    }
    &__table{
        @include clearfix();
        border:1px solid var(--disable-color);
        border-left: none;
        border-right: none;
        position: relative;
        padding-left: 185px;
        padding-top: 65px;
    }
    &__label{
        @include width-height(185px, 65px);
        border-right: 1px solid var(--disable-color);
        border-bottom: 1px solid var(--disable-color);
        float: left;
        margin-left: -185px;
        margin-top: -65px;
        padding: 10px;
        position: relative;
        &:before{
            @include position(absolute, 33px,null,null,0);
            @include width-height(100%, 1px);
            background-color: var(--disable-color);
            content: "";
            transform: skew(18deg, 18deg);
        }
        h5{
            padding-bottom: 0;
            text-align: left;
            &:first-child{
                margin-bottom: 12px;
                text-align: right;
            }
        }
    }
    &__head{
        @include clearfix();
        height: 65px;
        border-bottom: 1px solid var(--disable-color);
        ul{
            padding: 0 3px;
            float: left;
        }
        ul, li{
            height: 65px;
            vertical-align: top;
        }
        li{
            font-weight: var(--weight-medium);
            display: inline-block;
            width: 185px;
            text-align: center;
            line-height: 65px;
            margin: 0 2px;
            img {
                vertical-align: middle;
                padding-left: 10px;
                cursor: pointer;
            }
            .delete{
                @include opacity-visibility(0, hidden);
                cursor: pointer;
            }
            &:hover{
                .delete{
                    @include opacity-visibility(1, visible);
                    @include translate(0, -50%);
                }
            }
        }
    }
    &__labelcol{
        border-right: 1px solid var(--disable-color);
        float: left;
        margin-left: -185px;
        width: 185px;
        position: relative;
        top: -3px;
        ul, li{
            display: block;
        }
        li{
            @include width-height(185px, 65px);
            display: grid;
            grid-template-columns: auto auto auto;
            border-bottom: 1px solid var(--disable-color);
            font-weight: var(--weight-medium);
            padding: 15px;
            img {
                vertical-align: middle;
                cursor: pointer;
            }
            p {
                color: var(--text-on-teritary);
                display: block;
                font-size: calc(var(--body-font-size) - 4px);
                font-weight: var(--weight-medium);
                line-height: 14px;
            }
            .delete{
                @include opacity-visibility(0, hidden);
                cursor: pointer;
            }
            &:hover{
                .delete{
                    @include opacity-visibility(1, visible);
                }
            }
        }
        &.matrix__moveCol{
            margin-top: 0 !important;
        }
    }
    &__add{
        height: 65px;
        position: relative;
        padding: 15px;
        width: max-content;
        float: left;
        z-index: 3;
        > span{
            cursor: pointer;
            color: var(--primary-color);
            display: block;
            font-weight: var(--weight-medium);
            line-height: 36px;
        }
    }
    &__list{
        li{
            border-bottom: 1px solid var(--disable-color);
            height: 65px;
            padding: 7px 3px;
        }
    }
    &__card{
        @include width-height(185px, 50px);
        border: 1px solid var(--disable-color);
        border-radius: 4px;
        display: inline-block;
        vertical-align: top;
        margin: 0 3px;
        position: relative;
        padding: 8px;
        .edit-delete{
            @include display-flex(flex);
            @include justify-content(flex-end);
            @include opacity-visibility(0, hidden);
            cursor: pointer;
            img{
                @include width-height(15px, 15px);
            }
        }
        &:hover{
            .edit-delete{
                @include opacity-visibility(1, visible);
                @include translate(0, -50%);
            }
        }
    }
    &__content{
        @include display-flex(flex);
        @include flex-direction(column);
        p, span{
            font-size: calc(var(--body-font-size) - 4px);
            font-weight: var(--weight-medium);
            line-height: 14px;
        }
        span{
            color: var(--text-on-teritary);
            display: block;
            font-weight: var(--weight-medium);
        }
    }
    
    &__cardadd{
        @include width-height(100%, 100%);
        @include position(absolute, 0, 0, 0, 0);
        background-color: var(--white-color);
        border-radius: 4px;
        cursor: pointer;
        text-align: center;
        span{
            line-height: 50px;
            text-indent: 37px;
            display: block; 
        }
        &:before{
            @include position(absolute, 50%, null, null, 45%);
            @include width-height(20px, 20px);
            background-color: var(--primary-color);
            border-radius: 50%;
            content: "+";
            color: var(--white-color);
            line-height: 20px;
            transform: translate(-50%, -50%);
            text-align: center;
        }
    }
    &__textrow{
        @include clearfix();
        margin: 0 -5px;
        > div {
            display: inline-block;
            vertical-align: top;
            padding: 0 5px;
            width: 33.333%;
        }
    }   
    &__button{
        @include display-flex(flex);
        @include justify-content(space-between);
    }
    &--active{
        margin-top: -65px;
    }
    &__sheild{
        overflow-x: scroll;
        overflow-y: hidden;
        margin-top: -65px;
        &::-webkit-scrollbar-track {
            border-radius: 3px;
        }
        &::-webkit-scrollbar {
            width: 3px;
            height: 3px;
        }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: var(--border-color);
        }
    }
    &__overhide{
        overflow: inherit !important;
        display: flow-root;
    }
}
