@import 'src/scss/mixins';

.docker{
    @include position(fixed, null, null, 85px, 50%);
    @include display-flex(flex);
    @include align-items(center);
    background-color: var(--secondary-color);
    border-radius: 7px;
    box-shadow: 0 3px 18px 0 rgba(255, 131, 97, 0.16);
    height: 50px;
    padding: 5px 10px;
    transform: translateX(-50%);
    z-index: 9;
    ul{
        @include display-flex(flex);
        @include align-items(center);
        padding: 0 10px;
        position: relative;
        &:before, &:after{
            @include width-height(1px, 20px);
            background-color: var(--white-color);
            content: '';
            position: absolute;
            top:50%;
            transform: translateY(-50%);
        }
        &:before{
            left: 0;
        }
        &:after{
            right: 0;
        }
        li{
            display: inline-block;
            list-style: none;
            text-align: center;
            margin: 0 5px;
            width: 24px;
            img{
                width: 20px;
                vertical-align: middle;
            }
        }
    }
    &__list{
        color: var(--white-color);
        font-size: calc(var(--body-font-size) - 2px);
        padding: 0 10px;
        span{
            cursor: pointer;
        }
        
    }
    &--disable{
        opacity: 0.5;
        cursor: not-allowed;;
        img{
            pointer-events: none;
        }
     }
     &--active{
         cursor: pointer;
     }
}