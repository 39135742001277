@import 'scss/index';
@import 'scss/mixins';

.envelope {
  @include display-flex(flex);
  @include flex-wrap(wrap);

  &__search {
    @include display-flex(flex);
    @include flex-wrap(nowrap);
    @include align-items(center);
    @include width-height(100%, 40px);
    color: var(--text-on-secondary);
    border: solid 1px var(--text-on-teritary);
    background-color: var(--white-color);
    padding-left: 10px;
    margin-bottom: 6px;
    border-radius: 4px;
    img{ 
      height: 20px;
      width: 20px;
    }
    input {
      width: 100%;
      height: 100%;
      border: none;
      outline: none;
      padding: 0 10px;
    }
  }

  &__facts {
    border-right: solid 1px var(--disable-color);
    flex: 0 0 25%;
    max-width: 25%;
    padding: 0 18px;
  }

  &__tablist {
    @include position(relative);
    ul {
      @include display-flex(flex);
      @include justify-content(space-evenly);
      margin-bottom: 16px;
      list-style-type: none;
      text-align: center;
      li {
        color: var(--disable-color);
        padding: 9px;
        border: 2px solid transparent;
        cursor: pointer;
        font-size: calc(var(--body-font-size));
        font-weight: 500;
        transition: all 0.3s ease;

        &.is-active {
          color: #495281;
          border-bottom: 2px solid #0072fd;
        }
      }
      p {
        display: inline-block;
        padding-right: 5px;
      }
      span {
        background: var(--primary-color);
        color: var(--white-color);
        font-size: calc(var(--body-font-size) - 4px);
        display: inline-block;
        width: 28px;
        padding: 3px;
        border-radius: 11px;
      }
    }
  }

  &__factlist {
    overflow-x: hidden;
    margin-right: -10px;
    padding-right: 10px;
  
    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    &::-webkit-scrollbar {
      width: 3px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #a5b1c8;
    }

    .list-item {
      @include display-flex(flex);
      @include align-items(center);
      border-radius: 4px;
      border: solid 1px var(--disable-color);
      background-color: var(--white-color);
      cursor: grab;
      padding: 5px 40px 5px 5px;
      margin-bottom: 6px;
      text-transform: capitalize;
      word-break: break-all;
    
      span {
        @include width-height(26px, 26px);
        border-radius: 4px;
        color: var(--white-color);
        display: inline-block;
        text-align: center;
        padding: 5px;
        margin-right: 5px;
        line-height: 18px;
        font-size: calc(var(--body-font-size) - 4px);
        text-transform: uppercase;
      }
    
      .tooltip {
        @include position(absolute);
        cursor: pointer;
        right: 0%;
        opacity: 0;
        transition: visibility 0.3s linear, opacity 0.3s linear;
        z-index: 3;
        img {
          vertical-align: middle;
          margin: 0 15px;
        }
      }
      &:hover .tooltip {
        opacity: 1;
      }
    }
  }

  &__engine {
    flex: 0 0 75%;
    max-width: 75%;
    margin-right: -10px;
    padding-right: 10px;
    height: calc(100vh - 63px);
    padding: 0 15px;
    overflow-y: auto;
    overflow-x: auto;

    &::-webkit-scrollbar-track {
      border-radius: 3px;
    }
    &::-webkit-scrollbar {
      width: 3px;
      height: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: var(--disable-color);
    }
    padding-right: 5px;
  }

  .rule-definition {
    display: block;
    margin: 0 -5px;
    width: max-content;
  }
}

.create-fact {
  @include position(absolute);
  bottom: 0;
  cursor: pointer;
  overflow-y: hidden;
  right: -20px;
  width: 60px;
  z-index: 99;
}

// Dragula css

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

.gu-hide {
  left: -9999px !important;
}

.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}